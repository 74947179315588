import styled from 'styled-components'

export const ModalContainer = styled.div`
    display: flex; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); 

    font-family: 'Poppins';

`

export const ModalContent = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
    flex-direction: column;
    
    background-color: #fefefe;
    margin: 15% auto; 
    border: 1px solid #888;
    border-radius: 15px;
    min-height: 19.4rem;
    max-height: 25rem;
    width: 58.7rem;

    h1 {
        color: var(--blue);
        font-weight: 500;
        font-size: 1.5rem;
    }

    p {
        color: var(--blue);
        font-size: 1rem;
        padding: 1rem 0 3rem 0;
    }

    p span {
        font-weight: 900;
    }

    .isNone{
        padding: 1rem 0 0 0;
    }

    .botoes {
        display: flex;
        flex-direction: row;

        
        img {
            cursor: pointer;
        }

        img:first-child{
            margin-right: 2rem;
        }

        img:last-child{
            margin-left: 2rem;
        }
    }

    @media screen and (max-width:720px){
        width: 23rem;
        height: 14.813rem;

        margin: auto auto; 

        h1{
            font-size: 1.125rem;
            line-height: 1.688rem;
            text-align: center;
            padding: 1rem 1.25rem 0 1.25rem;
        }
        p{
            font-weight: 300;
            font-size: 0.75rem;    
            line-height: 1.125rem;
            text-align: center;
            padding: 1.125rem 0 1.25rem 0;
        }

        .botoes {
            img {
                cursor: pointer;
            }

            img:first-child{
                margin-right: 0.469rem;
                width: 9.563rem;
                height: 2.875rem;
            }

            img:last-child{
                margin-left: 0.469rem;
                width: 9.563rem;
                height: 2.875rem;
            }
        }
    }
    @media screen and (max-width:350px){
        width:19rem;
        
        p{
            padding: 0.5rem 0 0.6rem 0;
        }

        .botoes {
            img {
                cursor: pointer;
            }

            img:first-child{
                margin-right: 0.4rem;
                width: 8.5rem;
                height: 2.875rem;
            }

            img:last-child{
                margin-left: 0.4rem;
                width: 8.5rem;
                height: 2.875rem;
            }
        }
    }
`