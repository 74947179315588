import React, { useEffect } from "react";

import { Navigate } from "react-router-dom";

import { useAuth } from "../../hooks/AuthContext";

const AuxLogin = (props) => {
  const { signIn } = useAuth();

  const params = new URLSearchParams(window.location.search);

  const token = params.get("token");
  const name = params.get("name");

  useEffect(() => {
    // ajeitar essa parte, pois fica nulo quando n tem nada
    if (token != null) {
      signIn({ token, name });
    }
  }, [token, name, signIn]);

  return <>{token !== undefined ? <Navigate to="/" /> : <Navigate to="/" />}</>;
};

export default AuxLogin;
