import React, { useState, useRef, useEffect } from "react";

import { Container, FilterName } from "./styles";

export default function Dropdown({
  escolhidos,
  setEscolhidos,
  type,
  data,
  title,
}) {
  const ref = useRef()

  /* const [expanded, setExpanded] = useState(false); */
  const [isAll, setIsAll] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      const checkboxe = document.getElementById("checkboxes-" + type);
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {

        checkboxe.style.display = "none"

        setIsMenuOpen(false)
      }

    }
    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen, type])

  function showCheckbox() {
    const checkboxe = document.getElementById("checkboxes-" + type);
    if (!isMenuOpen) {
      checkboxe.style.display = "flex";
      setIsMenuOpen(true);
    } else {
      checkboxe.style.display = "none";
      setIsMenuOpen(false);
    }
  }

  function alterarCheckboxes(aux) {
    if (aux.target.id === "Todos" + type) {
      const checkboxes = document.getElementsByName("checkboxes[]" + type);

      if (!isAll) {
        setEscolhidos(data);
        setIsAll(true);
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true;
        }

        return;
      }

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      setEscolhidos([]);
      setIsAll(false);
      return;
    }

    const isSelected = escolhidos.filter((a) => a === aux.target.id);
    if (isSelected.length === 0) {
      setEscolhidos([...escolhidos, aux.target.id]);
    } else {
      setEscolhidos(escolhidos.filter((item) => item !== aux.target.id));
      //Se quiser, quando desmarcar um, descamar o all
      //setIsAll(false);
    }
  }

  return (
    <Container ref={ref} isEmpty={escolhidos.length <= 0}>
      <FilterName>{title}:</FilterName>
      <div className="selectBox" onClick={() => {
        setIsMenuOpen(oldState => !oldState)

        showCheckbox();


      }}>
        <select>
          <option>{escolhidos.length <= 0 ? `Selecione um ou mais ${type}` : data.length === escolhidos.length ? `Todos` : escolhidos.join(', ')}</option>
          {/* <option>Selecione os {type} para buscar</option> */}
        </select>
        <div className="overSelect" />
      </div>


      <div id={"checkboxes-" + type} onChange={(e) => alterarCheckboxes(e)}>
        <label htmlFor={"Todos" + type}>
          <input type="checkbox" id={"Todos" + type} />
          Todos
        </label>
        {data.map((item) => (
          <label htmlFor={item} key={item}>
            <input type="checkbox" id={item} name={"checkboxes[]" + type} />
            {item}
          </label>
        ))}
      </div>

    </Container>
  );
}
