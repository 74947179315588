import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    * {

        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        
    }  


    :root {
        --white: #FFF;
        --blue: #3A70BB;
        --light-blue: #D5EDF6;
        --dark-blue: #264D84;

    }

    body {
        overflow: overlay;
    }

    html {
 
        ::-webkit-scrollbar {
            width: 7px;
        }
        ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--blue);
            border-radius: 10px;
        }

        scroll-behavior: smooth;
        scroll-padding-top: 50px;

    }

`;
export default GlobalStyle;
