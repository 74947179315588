import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(

  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
    <link href="https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;900&family=Roboto:wght@400;500;700;900&display=swap" rel="stylesheet" />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
