import React from "react";
import LogoVortex from "../../assets/Nimbus/LogoVortex.svg";
import LogoUnifor from "../../assets/Nimbus/LogoUnifor.svg";
import LogoDTech from "../../assets/Nimbus/LogoDTec.svg";
import {
  LeftContainer,
  Content,
  ImageContainer,
  BigContainer,
  TitleContainer,
  RightContainer,
} from "./styles";
import ImgVoltar from "./../../assets/Nimbus/BotãoVoltar.svg";
import { useNavigate } from "react-router";

export default function About() {
  const navigate = useNavigate();
  return (
    <BigContainer>
      <LeftContainer>
        <Content>
          <TitleContainer>
            <img
              src={ImgVoltar}
              onClick={() => {
                navigate(-1); //, { replace: true })
              }}
              alt=""
            />

            <h1>Sobre o Sistema</h1>
          </TitleContainer>

          <RightContainer>
            <p className="aboutText">
              O aplicativo conhecido como "Nimbus", é fruto de um projeto
              multidisciplinar realizado com professores, pesquisadores e alunos
              de doutorado e iniciação científica das áreas de Administração e
              Ciências da Computação da Universidade de Fortaleza. Este projeto
              foi subsidiado por edital interno da Fundação Edson Queiroz, por
              intermédio da então Diretoria de Pesquisa, atualmente, Vice
              Reitoria de Pesquisa. O objetivo do aplicativo é possibilitar a
              captação de dados secundários, em plataformas de renome mundial,
              para realização de análises em estudos científicos, impulsionando
              assim, a elaboração e publicação de artigos e demais produções
              científicas e acadêmicas;
            </p>
            <div className="NameLists">
              <div>
                <h2>Professores</h2>
                <div className="NameContainer">
                  <div className="pList">
                    <p>Lara Feitosa</p>
                    <p>Milton Sousa</p>
                  </div>
                </div>
              </div>
              <div>
                <h2>Alunos</h2>
                <div className="NameContainer">
                  <div className="pList">
                    <p>Breno Colaço</p>
                    <p>Felipe Strozberg</p>
                    <p>Gabriel Brito</p>
                    <p>Isaque Bezerra</p>
                  </div>
                  <div className="pList">
                    <p>Lucas Alves</p>
                    <p>Rodrigo Avelino</p>
                    <p>Wendel Costa</p>
                  </div>
                </div>
              </div>
            </div>
          </RightContainer>
        </Content>
        <ImageContainer>
          <img src={LogoUnifor} alt="" />
          <img src={LogoDTech} alt="" />
          <img src={LogoVortex} alt="" />
        </ImageContainer>
      </LeftContainer>
    </BigContainer>
  );
}
