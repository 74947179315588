import React from "react";

import { Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import AuxLogin from "../pages/AuxLogin";
import Home from "../pages/Home";
import About from "../pages/About";

import { useAuth } from "../hooks/AuthContext";

/* const PrivateRoute = ({ component: Component, ...rest }) => {
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Navigate to={{ pathname: '/', state: { from: props.location } }} />
        )
    )} />
} */

export default function Routers() {
  const { authenticated } = useAuth();

  return (
    <Routes>
      {authenticated === false ? (
        <>
          <Route exact path="/" element={<Login />} />
          <Route path="/aux" element={<AuxLogin />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Home />} />
        </>
      )}

      <Route path="/about" element={<About />} />
    </Routes>
  );
}
