import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--dark-blue);


    min-height: 100vh;
    min-width: 100vw;
    
    background-image: url("/Nimbus/Imagem Fundo Login.png");
    background-position: bottom left;
    background-size: cover;

    .logos{
        display:flex;
        flex-direction:row;
        align-items:center;
        align-self:flex-start;
        width:100%;
        max-width:37.5rem;
        height:auto;
        margin-top:auto;
        padding-bottom:1.125rem;
        padding-left:2rem;
    }

    .logoVortex{
        width: 100%;
        max-width:18.75rem;
        max-height:auto;
        padding: 0 1rem;
        filter: brightness(100);
    }
    .logoDTec{
        width: 100%;
        max-width: 14.125rem;
        height: auto;
        padding: 0 1rem;
        filter: brightness(100)
    }
    
    
    @media (max-width: 750px) {
        justify-content:center;

        .logos{
            margin-top:auto;
            padding-bottom:1.125rem;
            padding-left:0;
        }
    }

    


`


export const Content = styled.div`
    display: flex;
    margin:  auto 10rem 0 0;
    padding: 1rem;
    width: 29.063rem;
    height: 36.188rem;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-end;
    
    position: relative;
    
    flex-direction: column;

    background-color: var(--white);
    border-radius: 20px;
    
    opacity: 0.91;

    #containerLogo{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 3rem;
        
        img {
            width: 14rem;
            filter: brightness(1.2);	
        }

        img:last-child{
            width:14.938rem;
            margin-top: 2rem;
        }
    }

    #containerBotoes {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 1.75rem;
        width: 100%;

        .sobre {
            display: flex;
            background-color: var(--blue)
        }

    }



    @media screen and (max-width:750px){
        margin: auto auto 0 auto;
    }
    @media screen and (max-width:620px){
        width:17.5rem;
        height:27.5rem;
        
        #containerLogo img{
            width:7.813rem;
        }
        #containerLogo img:last-child{
            margin-top:1.5rem;
            width:8.313rem;
        }
        #containerBotoes{
            padding-top:1.875rem;
        }
    }

`

export const ButtonAbout = styled.button`
    display: flex;
    border-radius: 1.25rem;

    padding: .25rem 0;
    
    position: absolute;
    bottom: 0;
    margin-bottom: 2.5rem;
 
    background-color: transparent;
    color: var(--blue);
    width: 41%;
    
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 2.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    
    white-space: nowrap;
    
    border-color: var(--blue);
    border-width: 0.1rem;


    cursor: pointer;

    font-weight:500;
    font-size:0.875rem;

    img {
        min-width: 1.125rem;
        max-width: 2;
    }
    
    @media screen and (max-width:750px){
        padding: .25rem 1.5rem;
        
        justify-content:space-around;
    }
    @media screen and (max-width:620px){
        padding: .25rem .75rem;
        width:14.188rem;
        height: 2.5rem;
        justify-content:space-around;
        margin-bottom:1.5rem;
    }
`
