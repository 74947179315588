import React from 'react';
import { useNavigate } from 'react-router'

import { ModalContainer, ModalContent } from './styles';

import BotaoPermanecer from '../../assets/Nimbus/Botão Permanecer.svg'
import BotaoSair from '../../assets/Nimbus/Botão Sair.svg'

import { useAuth } from '../../hooks/AuthContext'

export default function ModalError({ errorTitle, errorMessage, setIsModalError }) {
    const navigate = useNavigate();

    const { signOut } = useAuth();

    function sair() {
        signOut();
        navigate('/');
    }
    
    return (
        <ModalContainer>
            <ModalContent>
                <h1>{errorTitle}</h1>
                <p>{errorMessage}</p>

                <section className="botoes">
                    <img src={BotaoPermanecer} alt="" onClick={() => setIsModalError(false)} />
                    <img src={BotaoSair} alt="" onClick={() => { sair() }} />
                </section>

            </ModalContent>
        </ModalContainer>

    )
}