import styled from "styled-components";

export const Foot = styled.div`
  display: flex;
  flex-direction: Column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  position: relative;
  padding-top: 2rem;
  width: 80%;

  padding-bottom: 3rem;

  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .atualizar {
    height: 1.875rem;
    cursor: pointer;
  }
  .buscar {
    cursor: pointer;
  }

  .Mobile {
    display: none;
  }

  @media screen and (max-width: 620px) {
    justify-content: center;
    .atualizar {
      display: none;
    }
    .Row {
      justify-content: center;
    }
    .buscar {
      padding-bottom: 1.188rem;
    }

    .Mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 80vw;
    }
    .About {
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
    }
    .About p {
      padding-left: 0.313rem;

      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
    .atualizarMobile {
      width: 6.188rem;
      height: 1.313rem;

      cursor: pointer;
    }
  }
`;
