import React from "react";
import { Foot } from "./styles";

import atualizar from "../../assets/Nimbus/Atualizar Bancos de Dados.svg";
import buscar from "../../assets/Nimbus/Buscar.svg";

import SobreIcone from '../../assets/Nimbus/Sobre Ícone.svg';
import atualizarMobile from "../../assets/Nimbus/Atualizar Mobile.svg"

import { useNavigate } from 'react-router-dom';

export default function Footer({ refreshDB, setIsModalDownload }) {
    const navigate = useNavigate()

    return (
        <Foot>
            <div className="Row">
                <img className="atualizar" src={atualizar} alt="" onClick={() => refreshDB()} />
                <img className="buscar" src={buscar} alt="" onClick={() => setIsModalDownload(true)} />
            </div>
             <div className="Mobile">
                <div className='About' onClick={() => navigate('/about')}>
                    <img src={SobreIcone} alt="" />
                    <p>Sobre</p>
                </div>
                <img className="atualizarMobile" src={atualizarMobile} alt="" onClick={() => refreshDB()}/>
            </div>
        </Foot>
    )
}