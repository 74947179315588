import styled, { keyframes } from "styled-components";

const Animation = keyframes`
    from { height: 100%; }
    to {height: 0;}
    `;
export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  font-family: "Poppins";
`;

export const ModalContent = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
    flex-direction: column;
    background-color: #fefefe;
    margin: 15% auto; 
    border: 1px solid #888;
    border-radius: 15px;
    z-index:2;

    position: relative;
    .sair{
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        width: 30px;
        z-index: 10;
        cursor: pointer;
    }
    .cheia{
        position:absolute;
        width:448px;
        height:281px;
    }
    .frame{
        justify-items:center;
        animation: ${Animation} 5s linear infinite;
        object-fit:cover;
        overflow:hidden;
    }
    .frame img{
        filter: opacity(0.7) brightness(10) grayscale(0.7);
    }

    @media screen and (max-width:720px){
        margin: auto auto; 
        
        height:15rem;
        
        
        .vazia{
            display:flex;
            flex-direction:column;
            align-items:center;
        }
        .vazia img{
            width:300px;
            height:183px;
        }
        .frame{
            display:flex;
            flex-direction:column;
            align-items:center;
        }
        .frame img{
            margin-top:45.55px;
            width:300px;
            height:183px;
        }
    }
`