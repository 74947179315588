import React from "react";

import { SiteItem } from "./styles";

import checkBoxVazio from "../../assets/Nimbus/Seleção Site Vazio.svg"
import checkBoxSelected from "../../assets/Nimbus/Seleção Site.svg"

import globe from "../../assets/Nimbus/Globo.png"

export default function SiteCard({ databank, name, isEnd, isActive, itemsSelected, setItemsSelected, type, linkSite }) {

    function desabilitarCheckBox() {

        setItemsSelected(itemsSelected.filter(item => item.databank !== databank))
    }

    function habilitarCheckBox() {
        setItemsSelected([{ databank, name }, ...itemsSelected])
    }
    const handleChildElementClick = (e) => {
        e.stopPropagation()
     }


    return (
        <SiteItem isActive={isActive} onClick={()=>{ isActive ? desabilitarCheckBox() : habilitarCheckBox()}}>
            <div className="Column">
                <div className="Row" >
                    {isActive ? <img className="checkBox" src={checkBoxSelected} alt="" onClick={() => desabilitarCheckBox()} /> : <img className="checkBox" src={checkBoxVazio} alt="" onClick={() => habilitarCheckBox()} />}

                    <p className="name">{name}</p>
                    <div className="rightSide">
                        <p>{type}</p>
                        <div className='rectangle'>
                        </div>
                        <a onClick={(e) => handleChildElementClick(e)} href={linkSite} target="_blank" rel="noreferrer"><img className="globe" src={globe} alt="" /></a>
                    </div>
                </div>
                {!isEnd ? <hr /> : null}
            </div>
        </SiteItem >
    )
}