import styled from "styled-components";

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem 0.8rem 4rem;

  flex-direction: row;

  background-color: #dbeaef;
  color: var(--blue);

  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 1.143rem;
  letter-spacing: 0.1em;

  @media screen and (max-width: 620px) {
    background-color: #ffffff;
    padding: 0.6rem 0.8rem 0.6rem 1rem;

    font-weight: 300;
    font-size: 0.875rem;
  }
`;

export const LeftSide = styled.div`
  display: flex;

  align-items: center;

  img {
    width: 6.5rem;
    margin-right: 2rem;
    filter: brightness(1.2);
  }

  img:last-child {
    width: 7.5rem;
  }
  @media screen and (max-width: 620px) {
    img {
      width: 2.8rem;
    }
    img:last-child {
      display: none;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;

  align-items: center;


  cursor: pointer;

  p {
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--blue);
  }

  img {
    width: 1.8rem;
    margin-right: 0.5rem;
  }
  .sobre {
    display: flex;
    align-items: center;
    height: 1.8rem;
  }
  .sair {
    display: flex;
    align-items: center;
    height: 1.8rem;
  }

  .rectangle {
    border: 1.1px solid #7692b8;
    background-color: #7692b8;
    margin: 0 1.875rem;
    width: 2px;
    height: 33px;
    border-radius: 10px;
  }
  @media screen and (max-width: 620px) {
    p {
      font-size: 0.9rem;
      margin-top: 0.4rem;
    }
    img {
      max-height: 1.1rem;
      margin-top: 0.2rem;
      width: auto;
    }
    .sobre {
      display: none;
    }
    .rectangle {
      display: none;
    }
    .sair {
      height: 1rem;
    }
  }
`;
