import styled, { css } from "styled-components";

export const SiteItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 1.2rem;
    margin: 0 1.5rem;

    img {
      width: 1.125rem;
      height: 1.125rem;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  padding: 1.375rem 1.5rem 0 1.5rem;

  letter-spacing: 0.1rem;
  line-height: 1.6rem;

  .name {
    padding-left: 1.5rem;
    min-width: 15rem;
  }
  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    font-weight: normal;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1rem;
    line-height: 1.4rem;
    p {
      font-weight: normal;
      font-size: 1rem;
    }
  }
  a {
    display: flex;
    align-items: center;
  }
  .rectangle {
    border: 0.25px solid #3a70bb;
    background-color: #3a70bb;
    margin: 0 1.75rem;
    width: 0.5px;
    height: 31px;
    border-radius: 10px;
  }
  img {
    cursor: pointer;
  }

  hr {
    display: flex;

    align-self: center;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background: #c4c4c4;
  }
  @media screen and (max-width: 620px) {
    padding: 1.375rem 0.5rem 0 0.5rem;

    .Row {
      margin: 0 0 0 0.5rem;
    }
    .Row p{
      font-size: 0.75rem;
    }
    img {
      height: 1.25rem;
      width: 1.25rem;
    }

    .name {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      min-width: 8rem;

      font-style: normal;
      font-weight: normal;
      line-height: 1.313rem;
    }
    .rectangle {
      margin: 0 0.25rem;
      height: 40px;
    }
    a {
      margin-left: auto;
    }
    .rightSide {
      margin-left: auto;
    }
    .rightSide p {
      width: auto;
      text-align: justify;
      text-justify: inter-word;
      font-size: 0.6rem;
      line-height: 0.6rem;
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--blue);
      color: var(--white);

      :last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        hr {
          opacity: 0;
        }
      }
      :first-child {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .globe {
        filter: brightness(100);
      }

      hr {
        opacity: 0;
      }
    `}
`;
