import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global'

import AppProvider from './hooks'

import Routers from './routes'

function App() {
  return (
    <Router>
      <AppProvider>
        <Routers />
      </AppProvider>
      <GlobalStyle />
    </Router>
  );
}

export default App;
