import styled, { css } from 'styled-components'

export const MainButton = styled.button`
    display: flex;
    border-radius: 1.25rem;
    padding: .25rem 2rem .25rem .0;
    
    ${props => css`
        background-color: ${props.corFundo};
        color: ${props.corLetra};
        width: ${props.largura};
    `}
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 2.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    
    white-space: nowrap;
    
    border-color: var(--blue);
    border-width: 0.1rem;

    margin-top: 0.75rem;

    cursor: pointer;

    font-weight:500;
    font-size:0.875rem;

    img {
        min-height: 1.125rem;
        max-height: 2;
    }
    
    @media screen and (max-width:750px){
        padding: .25rem 1.5rem;
        
        justify-content:space-around;
    }
    @media screen and (max-width:620px){
        padding: .25rem .75rem;
        width:14.188rem;
        height: 2.5rem;
        justify-content:space-around;
    }
`