import React, { useState } from 'react';

import { Container, Content, ButtonAbout } from './styles'

import ModalErrorLogin from "../../components/ModalErrorLogin";

import logoVortex from './../../assets/Nimbus/LogoVortex.png'//faltando svg
import logoDTec from './../../assets/Nimbus/Logo-DTec-(1)-Branco 1.svg'

import LogoNimbus from './../../assets/Nimbus/Nimbus Logo.svg'
import NimbusNome from './../../assets/Nimbus/Nimbus Nome.svg'

import LogoFacebook from './../../assets/Nimbus/VectorFace.svg'
import LogoGoogle from './../../assets/Nimbus/VectorGoogle.svg'
import LogoUnifor from './../../assets/Nimbus/logo unifor.svg'

import Botao from '../../components/Botao'

import api from '../../services/api';
import { useNavigate } from 'react-router'

//import { useAuth } from '../../hooks/AuthContext'

export default function Login(props) {

    const [isModalError, setIsModalError] = useState(false);
    const [error, setError] = useState({});

    const navigate = useNavigate();

    function validarFacebook() {
        console.log('Validar Facebook')
        api.get('/oauth/facebook', {
            headers: {

                "Cache-Control": 'no-cache, no-store',
                Pragma: 'no-cache',
            },
        }
        )
            .then((res) => {
                window.location.href = res.data;
            })
            .catch((err)=>{
                setIsModalError(true);
                setError({ errorTitle: "Login Error", errorMessage: err.message });
            })

    }

    function validarGoogle() {
        console.log('Validar Google')
        api.get('/oauth/google', {
            headers: {

                "Cache-Control": 'no-cache, no-store',
                Pragma: 'no-cache',
            },
        }
        )
            .then((res) => {
                window.location.href = res.data;
            })
            .catch((err)=>{
                setIsModalError(true);
                setError({ errorTitle: "Login Error", errorMessage: err.message });
            })

    }


    return (
        <Container>
       
       
       {isModalError ? (
            <ModalErrorLogin
            errorTitle={error.errorTitle}
            errorMessage={error.errorMessage}
             setIsModalError={setIsModalError}
            />
        ) : null} 

            <Content>
                <section id="containerLogo">
                    <img src={LogoNimbus} alt="" />
                    <img src={NimbusNome} alt="" />
                </section>

                <section id="containerBotoes">
                    <Botao corFundo={"var(--blue)"} corLetra={"var(--white)"} largura={"19.313rem"} imgPath={LogoFacebook} onClick={() => validarFacebook()}>Entrar com Facebook</Botao>
                    <Botao corFundo={"var(--blue)"} corLetra={"var(--white)"} largura={"19.313rem"} imgPath={LogoGoogle} onClick={() => validarGoogle()}>Entrar com o Google</Botao>
                    <ButtonAbout corFundo={"transparent"} corLetra={"var(--blue)"} largura={"50%"} id="sobre" imgPath={LogoUnifor} onClick={() => navigate('/about')}>
                        <img src={LogoUnifor} alt="" />
                        Sobre o Nimbus

                    </ButtonAbout>


                </section>
            </Content>
            <div className="logos">
                <div>
                    <img className="logoVortex" src={logoVortex} alt="" />
                </div>
                <div>
                    <img className="logoDTec" src={logoDTec} alt="" />
                </div>
            </div>

        </Container>
    )
}