import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 23.5rem;
  position: relative;

  @media screen and (max-width: 1400px){
      min-width: 22.5rem;
  }
 
  @media screen and (max-width: 800px){
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }
  .selectBox {
    position: relative;
    @media screen and (max-width: 800px){
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }
    
  }

  .selectBox select {
    display: flex;
    width:100%;
    
    font-size: 1rem;
    background-color: transparent;
    padding: .4rem .5rem;
    color: #707277;
    font-weight: 400;
    letter-spacing: 0.075rem;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    white-space: nowrap;
    border: 2px #757575 solid;
    margin-top: 0.5rem;
    cursor: pointer;
    pointer-events: none;

    ${props =>
    props.isEmpty === false && css`
      color: var(--blue);
    `}

    
    
    @media screen and (max-width: 1300px){
      min-width: 77vw;
      max-width: 81vw;
    
    }
    
    @media screen and (max-width: 800px){
      font-size: 0.6rem;
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }

  }
  


  .overSelect {
    position: absolute;
    width:100%;
    min-width:23.5rem;
    
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    cursor: pointer;
    @media screen and (max-width: 1300px){
      min-width: 77vw;
      max-width: 81vw;
    }
    @media screen and (max-width: 800px){
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }
  }

  #checkboxes-anos {
    display: none;
    width:100%;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    min-width:23.5rem;
    flex-direction: column;
    z-index: 9997;
    border: 1px #dadada solid;
    overflow: scroll;
    max-height: 50vh;
    label{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 0.875rem;
      padding: .3rem;
      max-width: 100%;
      align-items: center;
      cursor: pointer;
      :first-child{
        margin-top: .6rem;
      }
    }
    label input {
      margin-right: 1rem;
      margin-left: 1.1rem;
      cursor: pointer;
    }
    @media screen and (max-width: 1300px){
      min-width: 77vw;
      max-width: 81vw;
      width: 77vw;
      max-height: 60vh;
    }
    @media screen and (max-width: 800px){
      font-size: 0.6rem;
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }
    
    
    ::-webkit-scrollbar {
      width: 2rem;

      :horizontal {
        display: none;
      }
    }
    ::-webkit-scrollbar-track {
      margin-right: 0.68rem;

      margin-top: 0.1rem;
      margin-bottom: 0.2rem;
    }
    ::-webkit-scrollbar-thumb {
      border: solid 0.85rem white;
      height: 92%;
      background: var(--blue);

      border-radius: 100vw;
    }
  }

  }


  #checkboxes-paises {
    display: none;
    width:100%;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    min-width:23.5rem;
    max-height: 50vh;
    label{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 0.875rem;
      padding: .3rem;
      max-width: 100%;
      cursor: pointer;
      align-items: center;
      :first-child{
        margin-top: .6rem;
      }
    }
    label input {
      margin-right: 1rem;
      margin-left: 1.1rem;
      cursor: pointer;
    }
    flex-direction: column;
    z-index: 9998;
    border: 1px #dadada solid;
    overflow: scroll;

    @media screen and (max-width: 1300px){
      min-width: 77vw;
      max-width: 81vw;
      width: 77vw;
    }
    @media screen and (max-width: 800px){
      font-size: 0.6rem;
      min-width: 76vw;
      max-width: 76vw;
      
    }
    @media screen and (max-width: 620px){
      font-size: 0.6rem;
      min-width: 81vw;
      max-width: 81vw;
      
    }
    ::-webkit-scrollbar {
      width: 2rem;

      :horizontal {
        display: none;
      }
    }
    ::-webkit-scrollbar-track {
      margin-right: 0.68rem;

      margin-top: 0.1rem;
      margin-bottom: 0.2rem;
    }
    ::-webkit-scrollbar-thumb {
      border: solid 0.85rem white;
      height: 92%;
      background: var(--blue);

      border-radius: 100vw;
    }
  }

  }

  
`;
export const FilterName = styled.p`
  color: var(--blue);
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  min-width: 23.5rem;
  max-width: 200px;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
  @media screen and (max-width: 800px) {
    max-width: 0;
    min-width: 77vw;
    max-height: 34.5rem
    width: 81vw;
    font-weight: 400;
    margin-left: 1rem;
    font-size: 0.7rem;
    line-height: 1.125rem;
  }
`;
