import React from 'react';

import { MainButton } from './styles'

export default function Botao({ onClick, imgPath, children, corFundo, corLetra, largura }) {

    return (
        <MainButton onClick={onClick} corFundo={corFundo} corLetra={corLetra} largura={largura}>
            <img src={imgPath} alt="" />

            {children}
        </MainButton>
    )
}