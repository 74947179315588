import { createContext, useCallback, useState, useContext, useEffect } from 'react';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('@Nimbus:token')

        if (token != undefined && token != null) {
            setAuthenticated(true);
        }
    }, [])

    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@Nimbus:token')
        const name = localStorage.getItem('@Nimbus:name')

        if (token && name) {
            return { token, name: JSON.parse(name) }
        }

        return {};

    })

    const signIn = useCallback(async ({ token, name }) => {

        localStorage.setItem('@Nimbus:token', token);
        localStorage.setItem('@Nimbus:name', JSON.stringify(name));

        setData({ token, name });
        setAuthenticated(true);

    }, []);


    const signOut = useCallback(() => {
        localStorage.removeItem('@Nimbus:token');
        localStorage.removeItem('@Nimbus:name');
        setData({})
        setAuthenticated(false);
    }, []);

    return (
        <AuthContext.Provider value={{ name: data.name, signIn, signOut, authenticated }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const ctx = useContext(AuthContext);

    if (!ctx) {
        throw new Error('useAuth deve ser usado em ...')
    }

    return ctx;
}

export { AuthProvider, useAuth };