import React from 'react';
import { useNavigate } from 'react-router'

import { ModalContainer, ModalContent } from './styles';

import BotaoCancelar from '../../assets/Nimbus/Botão Cancelar.svg'
import BotaoSair from '../../assets/Nimbus/Botão Sair.svg'

import { useAuth } from '../../hooks/AuthContext'

export default function ModalLogout({ setIsModalLogout }) {
    const navigate = useNavigate();

    const { signOut } = useAuth();

    function sair() {
        signOut();
        navigate('/');
    }

    return (
        <ModalContainer>
            <ModalContent>
                <h1>Deseja realmente sair?</h1>

                <section className="botoes">
                    <img src={BotaoCancelar} alt="" onClick={() => setIsModalLogout(false)} />
                    <img src={BotaoSair} alt="" onClick={() => { sair() }} />
                </section>
            </ModalContent>
        </ModalContainer>

    )
}