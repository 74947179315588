import React from 'react';

import { NavbarContainer, LeftSide, RightSide } from './styles'

import NimbusLogo from '../../assets/Nimbus/Nimbus Logo.png';
import NimbusNome from '../../assets/Nimbus/Nimbus Nome.svg';

import SobreIcone from '../../assets/Nimbus/Sobre Ícone.png';
import SairIcone from '../../assets/Nimbus/Ícone sair.png';

import { useNavigate } from 'react-router-dom';


export default function Navbar({ setIsModalLogout }) {
    const navigate = useNavigate();

    return (
        <NavbarContainer>
            <LeftSide>
                <img src={NimbusLogo} alt="" />
                <img src={NimbusNome} alt="" />

            </LeftSide>

            <RightSide >
                <div className='sobre' onClick={() => navigate('/about')}>
                    <img src={SobreIcone} alt="" />
                    <p>Sobre</p>
                </div>
                <div className='rectangle'>
                </div>
                <div className='sair' onClick={() => setIsModalLogout(true)}>
                    <img className='sairImg' src={SairIcone} alt=''/>
                    <p>Sair</p>
                </div>
            </RightSide>

        </NavbarContainer >
    )
}

