import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  background-image: url("/Nimbus/Fundo Desktop.svg");
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--blue);
  min-height: 90vh;

  @media screen and (max-width: 620px) {
    min-height: 97vh;
  }
  h1 {
    margin: 1rem 0 0 0;
    font-weight: 700;
    font-size: 2.25rem;
    letter-spacing: 0.15rem;
    line-height: 3.25rem;
    font-family: "Baloo Chettan 2", cursive;

    @media screen and (max-width: 620px) {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }

  h3 {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    line-height: 1.5rem;

    @media screen and (max-width: 620px) {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      margin-left: 4.5rem;
      margin-right: 4.5rem;
    }
  }
  .filterContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    @media screen and (max-width: 620px) {
      width: 80%;
      h4 {
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.125rem;
      }
    }
  }
  .filterContainer h4 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 100%;
    letter-spacing: 0.15rem;
    margin-bottom: 0.5rem;
    font-family: 'Baloo Chettan 2', cursive;
    @media screen and (max-width: 800px) {
      font-size: 0.8rem;
    }
  }
  .filterArea {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 3rem;
    @media screen and (max-width: 1300px) {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }
  .SelectAll {
    display: flex;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 620px) {
      width: 90%;
      margin-top: 1.6rem;
    }
  }
  .SelectAll .alignContent{
    display:flex;
    flex-direction:row;
    padding: 0 3rem;

    @media screen and (max-width: 720px){
      padding: 0 0.75rem;
    }

    @media screen and (max-width: 620px){
      padding: 0 1rem;
    }
  }
  .SelectAll p {
    padding-left: 1.75rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;

    @media screen and (max-width: 620px) {
      padding-left: 0.625rem;
      font-weight: 500;
      font-size: 0.938rem;
      line-height: 1.375rem;
    }
  }
  .SelectAll img {
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;

    @media screen and (max-width: 620px) {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .checkbox {
    position: relative;
    width: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .checkbox #marked {
    position: absolute;
    z-index: 1;
    height:0.625rem;
    width: auto;
    margin-left: 0.25rem;
    @media screen and (max-width: 620px) {
      height: 0.625rem;
      width: auto;
    }
  }
`;

export const SitesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  gap: 1px;

  background: rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 32px 4px rgba(78, 80, 83, 0.1);
  border-radius: 15px;

  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;
