import React from "react";

import { ModalContainer, ModalContent } from "./styles";

import BotaoCancelar from "../../assets/Nimbus/Botão Cancelar.svg";
import BotaoDownload from "../../assets/Nimbus/Botão Dawnload.svg";

export default function ModalDownload({
  setIsModalDownload,
  handleDownload,
  isNoneSite,
  isNoneYear,
  isNoneCountry,
  yearCount,
  countryCount,
  databankCount,
}) {
  return (
    <ModalContainer>
      <ModalContent>
        <h1>Deseja realmente fazer o download dos arquivos?</h1>
        {isNoneYear ? (
          <p className="isNone">
            {" "}
            <span>Atenção:</span> Nenhum ano foi selecionado.
          </p>
        ) : <p className="isNone">Foram selecionados <span>{yearCount} {countryCount>1 ? "anos" : "ano"}</span> </p>}
        {isNoneCountry ? (
          <p className="isNone">
            {" "}
            <span>Atenção:</span> Nenhum país foi selecionado.
          </p>
        ) : <p className="isNone">Foram selecionados <span>{countryCount} {countryCount>1 ? "países" : "país"}</span> </p>}
        {isNoneSite ? (
          <p className="isNone">
            {" "}
            <span>Atenção:</span> Nenhuma Base de dados foi selecionada. Todas serão adicionadas
          </p>
        ) : <p className="isNone">Foram selecionados <span>{databankCount} {databankCount>1 ? "bases de dados" : "base de dados"}</span> </p>}
        <p>
          Os dados serão coletados e compactados para <span>{`NIMBUS_DATA_${ new Date().toLocaleString("pt-br").split(' ')[0].replaceAll("/", "") }.csv`}</span>
        </p>

        <section className="botoes">
          <img
            src={BotaoCancelar}
            alt=""
            onClick={() => setIsModalDownload(false)}
          />
          <img
            src={BotaoDownload}
            alt=""
            onClick={() => {
              handleDownload();
              setIsModalDownload(false);
            }}
          />
        </section>
      </ModalContent>
    </ModalContainer>
  );
}
