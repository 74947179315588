import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Navbar from "../../components/Navbar";
import SiteCard from "../../components/SiteCard";
import Footer from "../../components/Footer";

import ModalDownload from "../../components/ModalDownload";
import ModalLogout from "../../components/ModalLogout";
import Loading from "../../components/Loading";
import ModalError from "../../components/ModalError";

import Dropdown from "../../components/Dropdown";
import DropdownVariavel from "../../components/DropdownVariavel";

import checkBox from "../../assets/Nimbus/Seleção Site Vazio.svg";
import marked from "../../assets/Nimbus/Marcado.svg";

import { Container, Content, SitesContainer } from "./styles";

import api from "../../services/api";
import axios from "axios";

import { useAuth } from "../../hooks/AuthContext";

export default function Home() {
  const [itemsSelected, setItemsSelected] = useState([]);
  const [isAll, setIsAll] = useState(false);

  const [isModalDownload, setIsModalDownload] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [error, setError] = useState({});

  const [variaveisEscolhidas, setVariaveisEscolhidas] = useState([]);
  const [anosEscolhidos, setAnosEscolhidos] = useState([]);
  const [paisesEscolhidos, setPaisesEscolhidos] = useState([]);

  const [dataVariaveis, setDataVariaveis] = useState({});
  const [dataAnos, setDataAnos] = useState([]);
  const [dataPaises, setDataPaises] = useState([]);

  const [sites, setSites] = useState([]);
  const [sitesFiltrados, setSitesFiltrados] = useState([]);

  const controller = new AbortController();
  const signal = controller.signal;

  const navigate = useNavigate();

  const { signOut } = useAuth();

  function sair() {
    signOut();
    navigate("/");
  }

  const cfg = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("@Nimbus:token")}`,
    },
  };

  useEffect(() => {
    api
      .get("/databank/info", cfg)
      .then((response) => {
        console.log(response.data);
        const variables = response.data.filter;
        setDataVariaveis(variables);
        const anos = response.data.years;
        setDataAnos(anos);
        const countries = response.data.countries;
        setDataPaises(countries);
        const data = response.data.databanks;

        setSites(data);
        setSitesFiltrados(data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalError(true);
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 401) {
            sair();
          } else {
            setError({
              errorTitle: "Erro",
              errorMessage: error.response.data.message,
            });
          }
        } else if (error.request) {
          console.log(error.request);
          setError({ errorTitle: "Erro", errorMessage: error.message });
        } else {
          setError({ errorTitle: "Erro", errorMessage: error.message });
        }
      });
  }, []);

  useEffect(() => {
    let auxSitesFiltrados = [];
    for (let site of sites) {
      if (variaveisEscolhidas.includes(site.data)) {
        auxSitesFiltrados.push(site);
      }
    }
    if (auxSitesFiltrados.length > 0) {
      setSitesFiltrados(auxSitesFiltrados);
      setItemsSelected([]);
    } else {
      setSitesFiltrados(sites);
    }

    console.log(variaveisEscolhidas);
  }, [variaveisEscolhidas]);

  function selectAll() {
    setIsAll(!isAll);
    if (isAll) {
      setItemsSelected([]);
    } else {
      setItemsSelected(sitesFiltrados);
    }
  }

  function handleDownload() {
    let anos = null;
    if (anosEscolhidos.length > 0) {
      anos = JSON.stringify(anosEscolhidos);
    }

    let paises = null;
    if (paisesEscolhidos.length > 0) {
      paises = JSON.stringify(paisesEscolhidos);
    }

    let auxSites = [];
    if (itemsSelected.length > 0) {
      for (let site of itemsSelected) {
        auxSites.push(site.databank);
      }
      auxSites = JSON.stringify(auxSites);
    } else {
      auxSites = null;
    }

    axios({
      url: "https://nimbus-vortex.herokuapp.com/databank",
      headers: cfg.headers,
      method: "GET",
      responseType: "blob", // important
      params: { years: anos, countries: paises, filters: auxSites },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `NIMBUS_DATA_${new Date()
            .toLocaleString("pt-br")
            .split(" ")[0]
            .replaceAll("/", "")}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalError(true);
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 401) {
            sair();
          } else {
            setError({
              errorTitle: "Erro",
              errorMessage: error.response.data.message,
            });
          }
        } else if (error.request) {
          console.log(error.request);
          setError({ errorTitle: "Erro", errorMessage: error.message });
        } else {
          setError({ errorTitle: "Erro", errorMessage: error.message });
        }
      });
  }

  function refreshDB() {
    setIsLoading(true);
    api
      .put("/databank", {}, cfg, signal)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsModalError(true);
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 401) {
            sair();
          } else {
            setError({
              errorTitle: "Erro",
              errorMessage: error.response.data.message,
            });
          }
        } else if (error.request) {
          console.log(error.request);
          setError({ errorTitle: "Erro", errorMessage: error.message });
        } else {
          setError({ errorTitle: "Erro", errorMessage: error.message });
        }
      });
  }

  function handleExit() {
    setIsLoading(false);
    controller.abort();
    console.log("atualizaçao abortada");
  }

  return (
    <Container>
      <Navbar setIsModalLogout={setIsModalLogout} />

      {isModalDownload ? (
        <ModalDownload
          setIsModalDownload={setIsModalDownload}
          handleDownload={() => {
            handleDownload();
          }}
          isNoneSite={itemsSelected.length > 0 ? false : true}
          isNoneCountry={paisesEscolhidos.length > 0 ? false : true}
          isNoneYear={anosEscolhidos.length > 0 ? false : true}
          yearCount={anosEscolhidos.length}
          countryCount={paisesEscolhidos.length}
          databankCount={itemsSelected.length}
        />
      ) : null}
      {isModalLogout ? (
        <ModalLogout setIsModalLogout={setIsModalLogout} />
      ) : null}
      {isLoading ? (
        <Loading
          handleExit={() => {
            handleExit();
          }}
        />
      ) : null}

      {isModalError ? (
        <ModalError
          errorTitle={error.errorTitle}
          errorMessage={error.errorMessage}
          setIsModalError={setIsModalError}
        />
      ) : null}

      <Content>
        <h1>Busca De Dados</h1>
        <h3>Selecione sites onde a busca será realizada:</h3>
        <div className="filterContainer">
          <h4>Filtro de dados:</h4>
          <div className="filterArea">
            <DropdownVariavel
              escolhidos={variaveisEscolhidas}
              setEscolhidos={setVariaveisEscolhidas}
              type="variaveis"
              data={dataVariaveis}
              title={"Variáveis"}
            />
            <Dropdown
              escolhidos={paisesEscolhidos}
              setEscolhidos={setPaisesEscolhidos}
              type="paises"
              data={dataPaises}
              title={"Países"}
            />
            <Dropdown
              escolhidos={anosEscolhidos}
              setEscolhidos={setAnosEscolhidos}
              type="anos"
              data={dataAnos}
              title={"Anos"}
            />
          </div>
        </div>

        <div className="SelectAll">
          <div className="alignContent">
            <div className="checkbox">
              <img src={checkBox} alt="" onClick={() => selectAll()} />
              {isAll ? (
                <img
                  id="marked"
                  src={marked}
                  alt=""
                  onClick={() => selectAll()}
                />
              ) : null}
            </div>
            <p>Selecionar Todos</p>
          </div>
        </div>

        <SitesContainer>
          {sitesFiltrados.map((site, i) => (
            <SiteCard
              key={site.databank}
              databank={site.databank}
              name={`${
                site.databank.split("/")[0].split("_")[0][0].toUpperCase() +
                site.databank.split("/")[0].split("_")[0].slice(1)
              }  ${
                site.databank.split("/")[0].split("_")[1]
                  ? site.databank.split("/")[0].split("_")[1][0].toUpperCase() +
                    site.databank.split("/")[0].split("_")[1].slice(1)
                  : ""
              }`}
              isEnd={sitesFiltrados.length - 1 === i}
              isActive={
                itemsSelected.find((item) => item.databank === site.databank) !=
                null
              }
              itemsSelected={itemsSelected}
              setItemsSelected={setItemsSelected}
              type={site.data}
              linkSite={site.url}
            />
          ))}
        </SitesContainer>

        <Footer refreshDB={refreshDB} setIsModalDownload={setIsModalDownload} />
      </Content>
    </Container>
  );
}
