import React from 'react';

import { ModalContainer, ModalContent } from './styles';

import ImagemCheia from '../../assets/Nimbus/Carregamento Cheia.svg'
import sair from "../../assets/Nimbus/Sair Loading.svg"




export default function Loading({handleExit}) {


    return (
        <ModalContainer>
            <ModalContent>
                <img className='sair' src={sair} alt='' onClick={handleExit}/>
                <div className='vazia' >
                    <div className='filter'>
                        <img src={ImagemCheia} alt="" />
                    </div>
                </div>

                <div className='cheia' >
                    <div className='frame' id="frame">
                        <img src={ImagemCheia} alt="" />
                    </div>
                </div>
            </ModalContent>
        </ModalContainer>

    )
}