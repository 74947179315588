import React from 'react';

import { ModalContainer, ModalContent } from './styles';

import BotaoVoltar from '../../assets/Nimbus/Botão Voltar Web.svg'

export default function ModalError({ errorTitle, errorMessage, setIsModalError }) {
    
    return (
        <ModalContainer>
            <ModalContent>
                <h1>{errorTitle}</h1>
                <p>{errorMessage}</p>

                <section className="botoes">
                    <img src={BotaoVoltar} alt="" onClick={() => setIsModalError(false)} />
                </section>

            </ModalContent>
        </ModalContainer>

    )
}