import styled from "styled-components";
export const BigContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url("/Nimbus/Fundo Desktop.png");
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  width: 100%;
  margin-top: 2rem;
  h1 {
    font-family: "Baloo Chettan 2", cursive;
    font-size: 2.25rem; //calc(28 + 8 * ((100vw - 1000px) / 920))
    color: var(--dark-blue);
    font-weight: bolder;
    line-height: 3.375rem;
    letter-spacing: 0.2rem;
  }
  img {
    margin-left: -0.8rem;
    max-width: 5rem;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  @media screen and (max-width: 1600px) {
    margin-top: 0;
    img {
      max-width: 3.3rem;
    }
  }
  @media screen and (max-width: 825px) {
    h1 {
      margin-bottom: calc(100vh * 0.0209);
      color: var(--dark-blue);
      letter-spacing: 0.1rem;
    }
  }
  @media screen and (max-width: 825px) {
    flex-direction: column;
    gap: 1vh;
    img {
      margin-top: 0;
      align-self: flex-start;
      max-width: 3.3rem;
      width: 100%;
    }
    h1 {
      font-weight: bolder;
      line-height: 2.6rem;
      align-self: center;
      font-size: 1.75rem;
    }
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
  width: 100%;
  min-height: 100vh;

  @media screen and (max-width: 825px) {
    background-size: cover;
    gap: 1vh;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 84%;
  margin-top: 3.3rem;

  p {
    width: 80%;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin-top: calc(100vh * 0.032);
    color: var(--dark-blue);
    word-break: break-word;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.1rem;
    text-align: justify;
  }

  @media screen and (max-width: 825px) {
    width: 89%;
    margin-top: 1rem;
    p {
      margin-top: 0;
      margin-top: 0rem;
      line-height: 24px;
      font-weight: 400;
      text-align: left;
      //overflow: scroll;
    }
    /* p:last-of-type {
      display: none;
    } */
  }
  @media screen and (max-width: 825px) {
    p {
      font-size: calc(16px + 6 * ((100vw - 320px) / 680));
      //height: 40vh;
      font-weight: 400;
      width: 79%;
    }
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  width: 85%;
  gap: 5vw;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 5rem;
  max-height: none;
  height: auto;
  align-items: flex-start;
  cursor: initial;
  img {
    width: 11rem;
    height: auto;
  }

  img:first-child {
    width: 14.938rem;
    height: auto;
    align-self: center;
  }
  img:last-child {
    width: 15.313rem;
    height: auto;
    transform: translateY(13%);
  }
  @media screen and (max-width: 1600px) {
    img {
      max-width: 20rem;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 825px) {
    flex-direction: column;
    justify-content: center;
    width: 70%;
    align-items: center;
    gap: 0rem;
    margin: 0;
    margin-top: 4rem;
    img {
      margin-top: 1rem;
      margin-bottom: calc(100vh * 0.017);
      width: 100%;
      height: auto;
      max-width: 16.375rem;
    }
    img:first-child {
      width: 100%;
      height: auto;
    }
    img:last-child {
      width: 100%;
      height: auto;
    }
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  .NameLists {
    display: flex;
    flex-direction: column;
    gap: 2.6rem;
    h2 {
      margin-top: 0;
    }
    p {
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      color: var(--dark-blue);
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.1rem;
    }
  }
  .NameContainer {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 1rem;
  }
  .pList {
    padding: 0;
    p {
      margin-top: 0;
      margin-right: 4rem;
    }
  }

  h2 {
    font-family: "Baloo Chettan 2", cursive;
    margin-top: 5rem;
    font-size: 1.5rem;
    color: var(--dark-blue);
    font-weight: bolder;
    line-height: 3.375rem;
    letter-spacing: 0.2rem;
  }
  @media screen and (max-width: 1600px) {
    .aboutText {
      min-width: 30vw;
    }
  }
  @media screen and (max-width: 1100px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    p {
      align-self: center;
    }
    h2 {
      text-align: start;
    }
    .NameContainer {
      flex-direction: row;
      p {
        margin-right: 0;
      }
    }
    .NameLists {
      text-align: center;
      align-self: flex-start;
      margin-left: 5.1rem;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 825px) {
    justify-content: center;
    text-align: center;
    p {
      align-self: center;
    }
    h2 {
      text-align: start;
    }
    .NameContainer {
      flex-direction: column;
      p {
        margin-right: 0;
      }
    }
    .NameLists {
      margin-left: 0;
      align-self: center;
      flex-direction: column;
      text-align: center;
    }
  }
`;
